<template>
  <div id="container" v-if="change_rank_info">
    <section id="mypage">
      <div class="wrap">

        <div class="cts_box">
          <h2 class="sub_ttl"><span>塾生クラスの変更</span></h2>
          <p class="lead_txt" v-if="change_rank_info.price === 0">
            以下の内容で塾生クラスの変更を行います。<br>
            内容に間違いがないかご確認のうえ、画面下の「クラスを変更する」ボタンを押してください。
          </p>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">変更内容</h3>
          <div class="df_table">
            <dl class="flex">
              <dt>現在のクラスとお支払い方法</dt>
              <dd>
                {{ current_user.rank_name }}
                <template v-if="current_user.plan === 'plan_basic'">+ 四季報オンラインベーシック</template>
                <template v-if="current_user.plan === 'plan_premium'">+ 四季報オンラインプレミアム</template>
              </dd>
            </dl>
            <dl class="flex">
              <dt>変更後のクラス</dt>
              <dd>{{ change_rank_info.title }}</dd>
            </dl>
            <dl class="flex" v-if="false">
              <dt>変更後のお支払い方法</dt>
              <dd></dd><!--月額払い or 年額払い-->
            </dl>
            <dl class="flex" v-if="false">
              <dt>四季報ONLINE優待プラン</dt>
              <dd></dd><!--本科：加入プラン、本科プラス：変更後のクラスに「プレミアムプラン」が含まれます。-->
            </dl>
          </div>
        </div>

        <div v-bind:class="{ 'point-disabled': !change_rank_info.price }" v-if="false">

          <div class="cts_box">
            <h3 class="min_ttl">お会計内容</h3>
            <div class="register_box">
              <dl class="flex">
                <dt>{{ change_rank_info.title }} への変更</dt>
                <dd class="price">¥{{ delimited(change_rank_info.price) }}</dd>
              </dl>
              <dl class="flex" v-if="false">
                <dt>クーポンのご利用</dt>
                <dd class="item"></dd>
                <dd class="price">¥-6,500</dd>
              </dl>
              <dl class="flex" v-if="change_rank_info.use_point">
                <dt>ポイントのご利用</dt>
                <dd class="item"></dd>
                <dd class="price">-{{ delimited(change_rank_info.use_point) }}</dd>
              </dl>
              <dl class="flex total">
                <dt></dt>
                <dd class="item">お会計合計：</dd>
                <dd class="price">¥{{ delimited(change_rank_info.total_price) }}</dd>
              </dl>
            </div>
          </div>
        </div>

        <div class="btn_wrap mt20">
          <p class="alnC sp_alnL mb10">内容に変更なければ<br class="sp">こちらのボタンを押してください</p>
          <a href="javascript:void(0);" class="submit light arrow large sp_h_auto"
             v-bind:class="{disabled: !changeEnabled()}" @click="changeRank()">クラスを変更する</a>
        </div>

        <div class="btn_wrap mt40">
          <p class="alnC sp_alnL mb10">入力内容を変更する場合はこちらのボタンを押してください</p>
          <a href="javascript:void(0);" @click="$router.back()" class="submit light skeleton arrow back large">
            前のページにもどる
          </a>
        </div>

      </div>
    </section>

    <div id="b_listBox">
      <ul id="b_list">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>
          <router-link :to="{name: 'UsersCustomerAccount'}">マイページ</router-link>
        </li>
        <li>塾生クラスの変更</li>
      </ul>
    </div>
  </div>

</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  data() {
    return {
      change_rank_info: null,
      request_exec: false
    }
  },
  methods: {
    changeEnabled() {
      return this.current_user.rank !== this.change_rank_info.new_rank && this.change_rank_info.level !== 'error'
    },

    changeRank() {
      if (this.request_exec) return
      this.request_exec = true

      if (!confirm('クラス変更を実施しますか？')) {
        return
      }
      // ランク変更実施
      this.startUpload()
      this.axios
          .post(`${this.env.api_base_url}users/change_rank.json`, {
            new_rank: this.change_rank_info.rank,
            use_points: this.change_rank_info.use_point
          })
          .then((response) => {
            // console.log(response.data)
            // 必要なら決済開始
            if (response.data.url) {
              document.location.href = response.data.url
            } else if (response.data.message) {
              alert(response.data.message)
            } else {
              this.$router.push({name: 'UsersCustomerNoJoinRankChangeFinish'})
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishUpload()
            this.request_exec = false
          })
    }
  },

  mounted() {
    // form token
    if (!this.checkFormToken('RankChange')) return

    if (!this.current_user.no_join_rank_change_enabled) {
      this.$router.push({name: 'UsersCustomerAccount'})
      return
    }
    // 旧塾生専用のページ
    if (!this.$store.state.change_rank_info) {
      this.$router.push({name: 'UsersCustomerNoJoinRankChange'})
    } else {
      this.change_rank_info = this.$store.state.change_rank_info
      // console.log(this.change_rank_info)
    }
  }
}
</script>

<style>
.point-disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>